import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/src/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/packages/ui/src/primitives/Link/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterForm"] */ "/app/frontend/site/src/app/[locale]/(regular-layout)/_components/NewsletterForm/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieDeclaration"] */ "/app/frontend/site/src/components/cms/CookieDeclaration/CookieDeclaration.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQItemList"] */ "/app/frontend/site/src/components/cms/FAQItemList/FAQItemList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UGCFlowbox"] */ "/app/frontend/site/src/components/cms/UGCFlowbox/UGCFlowbox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Video"] */ "/app/frontend/site/src/components/cms/Video/Video.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/components/GoogleAnalytics/GTMPageLoadEvent.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/components/Image/Image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Pagination"] */ "/app/frontend/site/src/components/Pagination/Pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductListingView"] */ "/app/frontend/site/src/components/ProductListing/ProductListingView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SectionHeader"] */ "/app/frontend/site/src/components/SectionHeader/SectionHeader.tsx");
;
import(/* webpackMode: "eager" */ "/app/frontend/site/src/lib/store-config/context/storeConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.15.2_next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+te_0c4fe260f6ef0a4d7f2fd28a786cd267/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/next@15.2.4_@babel+core@7.23.9_@opentelemetry+api@1.9.0_@playwright+test@1.47.0_react-d_15f5c6c2f85109b313f8e5c0ab34b651/node_modules/next/dist/client/app-dir/link.js");
