"use client";

import { languageOf } from "@commerce-frontend/i18n/helpers";
import type { Locale } from "@commerce-frontend/i18n/types";
import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import { graphql } from "@commerce-frontend/types";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { useEffect, useRef } from "react";
import { useCookiebot } from "~/hooks/cookiebot";
import { useEnvVar } from "~/lib/config/context";

export const CookieDeclarationFragment = graphql(/* GraphQL */ `
	fragment CookieDeclarationFragment on CookieDeclaration {
		__typename
		id
	}
`);

type CookieDeclarationProps = {
	data: ResultOf<typeof CookieDeclarationFragment>;
	locale: Locale;
};

export const CookieDeclaration = ({ data, locale }: CookieDeclarationProps) => {
	const cookiebotId = useEnvVar("NEXT_PUBLIC_COOKIEBOT_ID");
	const cookiebot = useCookiebot();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.setAttribute("data-culture", languageOf(locale));
		script.src = `https://consent.cookiebot.com/${cookiebotId}/cd.js`;

		const parent = ref.current;
		parent.appendChild(script);

		return () => {
			parent.removeChild(script);
		};
	}, [cookiebotId, cookiebot]);

	if (!cookiebotId) {
		return <span>No Cookiebot ID</span>;
	}

	if (!cookiebot) {
		return null;
	}

	return <div ref={ref} {...storyblokDataAttributes(data)} />;
};
