"use client";

import { type FaqItemListFragmentFragment, graphql } from "@commerce-frontend/types";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { forwardRef } from "react";
import { FAQItem } from "~/components/FAQItem/FAQItem";

graphql(/* GraphQL */ `
	fragment FAQItemListFragment on FAQItemList {
		__typename
		id
		faqItems {
			...FAQItemFragment
		}
	}
`);

export const FAQItemList = forwardRef<
	HTMLDivElement,
	{
		data: FaqItemListFragmentFragment;
		className?: string;
	}
>(({ data, className, ...props }, ref) => {
	return (
		<div {...props} ref={ref} className={cn("flex flex-col gap-3xl", className)}>
			{data.faqItems.map((item) => (
				<FAQItem key={item.id} data={item} className="last:border-0 border-b border-b-gray-200" />
			))}
		</div>
	);
});
FAQItemList.displayName = "FAQItemList";
