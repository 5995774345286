"use client";

import type { ContentBlockImpressionEventData } from "@commerce-frontend/gtm-ecommerce";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import type { HeroFragmentFragment } from "@commerce-frontend/types";
import { graphql } from "@commerce-frontend/types";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { BREAKPOINTS } from "@commerce-frontend/ui/tailwind.config";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { cva } from "class-variance-authority";
import { useLocale } from "next-intl";
import { type HTMLAttributes, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { AssetImage } from "~/components/AssetImage/AssetImage";
import { Button } from "~/components/cms/Button/Button";
import { RichText } from "~/components/cms/RichText/RichText";
import { useNavigation } from "~/lib/navigation-provider";
import { deviceData, locationDataFromPathname, sendGTMEvent, userData } from "~/lib/send-gtm-event";

const HeroFragment = graphql(/* GraphQL */ `
	fragment HeroFragment on Hero {
		__typename
		id
		title
		variant
		imagePosition
		imageStyle
		bodyText {
			...RichTextFragment
		}
		buttons {
			...ButtonFragment
		}
		image {
			...ImageAsset
		}
	}
`);

type Props = {
	data: ResultOf<typeof HeroFragment>;
	categoryId?: string | undefined;
} & HTMLAttributes<HTMLDivElement>;

const getTextColour = (
	imagePosition: HeroFragmentFragment["imagePosition"],
	imageStyle: HeroFragmentFragment["imageStyle"],
	hasImage: boolean,
	defaultColorClass: string,
) =>
	cn([
		defaultColorClass,
		imagePosition === "right" && defaultColorClass,
		imagePosition === "full" && imageStyle === "dark" && hasImage && "text-white",
	]);

export const Hero = ({ className, data, categoryId }: Props) => {
	const { title, variant, bodyText, buttons, image, imagePosition, imageStyle } = data;
	const hasImage = Boolean(image?.filename);
	const imageHeight = variant === "hero" ? 720 : 360;
	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true,
	});
	const { previousUrl } = useNavigation();
	const locale = useLocale() as Locale;
	const pathname = usePathname();

	useEffect(() => {
		if (inView) {
			const buttonLinks = buttons?.map((button) => button.link?.url) ?? [];
			const buttonTitles = buttons?.map((button) => button.title) ?? [];

			sendGTMEvent<ContentBlockImpressionEventData>({
				event: "content_block_impression",
				device: deviceData(),
				location: locationDataFromPathname({
					locale,
					pathname,
					categoryId,
					previousUrl,
				}),
				timestamp: Date.now(),
				user: userData(),
				general: {
					campaign_type: null,
				},
				banner: {
					text: buttonTitles,
					link: buttonLinks,
					position: 1,
					element: "main content",
					id: data.id ?? null,
				},
			});
		}
	}, [inView]);

	const getImageRenditions = (variant: HeroFragmentFragment["variant"]) => {
		const imageHeight = variant === "hero" ? 720 : 360;

		const imageRenditions: Record<
			HeroFragmentFragment["imagePosition"],
			{
				aspectRatio: number;
				css?: string;
			}[]
		> = {
			right: [
				{
					aspectRatio: variant === "hero" ? 1 : 1.3,
				},
			],
			full: [
				{
					aspectRatio: 640 / imageHeight,
					css: "max-sm:block hidden",
				},
				{
					aspectRatio: 768 / imageHeight,
					css: "sm:max-md:block hidden",
				},
				{
					aspectRatio: 1024 / imageHeight,
					css: "md:max-lg:block hidden",
				},
				{
					aspectRatio: 1440 / imageHeight,
					css: "max-lg:hidden block",
				},
			],
		};

		return imageRenditions;
	};

	return (
		<div ref={ref} className="bg-gray-50">
			<div
				className={cn(
					`hero hero-${imageStyle}`,
					variant === "hero"
						? heroVariants({
								className,
								imageStyle,
								imagePosition,
							})
						: bannerVariants({
								className,
								imageStyle,
								imagePosition,
							}),
					imageStyle === "dark" &&
						imagePosition === "full" &&
						"relative before:content-[''] before:absolute before:inset-0 before:bg-black/40 before:z-[1]",
				)}
				{...storyblokDataAttributes(data)}
			>
				{hasImage && imagePosition
					? getImageRenditions(variant)[imagePosition].map(({ aspectRatio, css }, index, arr) => (
							<AssetImage
								key={`${aspectRatio}-${css}`}
								data={image}
								className={cn([
									imageVariants({
										imagePosition,
									}),
									css,
								])}
								aspectRatio={aspectRatio}
								priority={arr.length === index + 1}
								height={imageHeight}
								sizes={
									imagePosition === "full"
										? "(max-width: 1440px) 100vw, 1440px"
										: `(max-width: ${BREAKPOINTS.lg}) 100vw, (max-width: 1440px) 50vw, ${imageHeight}px`
								}
							/>
						))
					: null}

				<Container
					className={
						variant === "hero"
							? heroContainerVariants({ imagePosition })
							: bannerContainerVariants({ imagePosition })
					}
				>
					<div
						className={contentVariants({
							imagePosition,
							variant,
						})}
					>
						<span
							className={cn(
								"display-md font-semibold lg:display-xl",
								getTextColour(imagePosition, imageStyle, hasImage, "text-gray-900"),
							)}
						>
							{title}
						</span>

						{bodyText && (
							<RichText
								data={bodyText}
								locale={locale}
								contentClassName={`${getTextColour(imagePosition, imageStyle, hasImage, "text-gray-600")}`}
							/>
						)}

						{buttons?.length ? (
							<div
								className={cn(
									"mt-4 flex flex-row flex-wrap gap-3 max-sm:flex-col md:mt-6",
									variant === "hero" && imagePosition === "full" && "max-sm:flex-row",
								)}
							>
								{buttons.map((button) => (
									<div key={button.id}>
										<Button data={button} className="max-sm:w-full" />
									</div>
								))}
							</div>
						) : null}
					</div>
				</Container>
			</div>
		</div>
	);
};

export const heroVariants = cva("relative flex max-w-full flex-col lg:min-h-[720px] lg:flex-row", {
	variants: {
		imageStyle: {
			dark: "",
			light: "text-gray-600",
		},
		imagePosition: {
			full: "max-lg:min-h-[720px] lg:h-[720px] lg:mx-auto lg:max-w-[1440px]",
			right: "pt-auto",
		},
	},
});

export const bannerVariants = cva("relative flex max-w-full flex-col lg:flex-row", {
	variants: {
		imageStyle: {
			dark: "",
			light: "text-gray-600",
		},
		imagePosition: {
			full: "max-h-[420px] lg:mx-auto lg:max-w-[1440px]",
			right: "pt-auto",
		},
	},
});

export const heroContainerVariants = cva("flex z-[2]", {
	variants: {
		imagePosition: {
			right: "",
			full: "max-lg:min-h-[720px] flex-col-reverse",
		},
	},
});

export const bannerContainerVariants = cva("flex z-[2]", {
	variants: {
		imagePosition: {
			right: "",
			full: "h-[360px] flex-col-reverse",
		},
	},
});

export const imageVariants = cva("top-0 object-cover z-0 lg:absolute", {
	variants: {
		imagePosition: {
			right:
				"left-1/2 aspect-square h-auto w-full lg:aspect-auto lg:h-full lg:w-auto lg:max-w-[50%] min-[1440px]:max-w-[720px]",
			full: "absolute size-full",
		},
	},
});

export const contentVariants = cva(
	"relative flex flex-col gap-4 py-6xl lg:gap-5 lg:pr-16 h-full justify-center",
	{
		variants: {
			variant: {
				hero: "lg:py-44",
				banner: "lg:py-9xl",
			},
			imagePosition: {
				right: "pt-3xl w-full lg:w-1/2",
				full: "",
			},
		},
	},
);
