"use client";

import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import { graphql } from "@commerce-frontend/types";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { type HTMLAttributes, useEffect } from "react";

export const UGCFlowboxFragment = graphql(/* GraphQL */ `
	fragment UGCFlowboxFragment on UGCFlowbox {
		__typename
		id
		key
		tags
	}
`);

type UGCFlowboxBlockProps = {
	data?: ResultOf<typeof UGCFlowboxFragment>;
	locale: string;
} & HTMLAttributes<HTMLDivElement>;

// https://help.getflowbox.com/en/articles/3569385-embedding-a-flow-in-a-react-application

const loadFlowbox = (): Promise<void> => {
	return new Promise((resolve) => {
		((d: Document, id: string) => {
			if (!window.flowbox) {
				const f: {
					q: unknown[];
				} = (...args: unknown[]) => {
					f.q.push(args);
				};
				f.q = [];
				window.flowbox = f;
			}

			if (d.getElementById(id)) {
				return resolve();
			}

			const s: HTMLScriptElement = d.createElement("script");
			const fjs: HTMLScriptElement = d.scripts[d.scripts.length - 1];

			s.id = id;
			s.async = true;
			s.src = "https://connect.getflowbox.com/flowbox.js";
			fjs.parentNode?.insertBefore(s, fjs);
			resolve();
		})(document, "flowbox-js-embed");
	});
};

export const UGCFlowbox = ({ className, data, locale }: UGCFlowboxBlockProps) => {
	useEffect(() => {
		loadFlowbox()
			.then(() => {
				window.flowbox("init", {
					container: `#${data?.__typename}-${data?.id}`,
					key: data?.key,
					locale: locale,
					tags: data?.tags?.split("\n") || [],
				});
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<div
			id={`${data?.__typename}-${data?.id}`}
			className={cn(["w-full", className])}
			{...(data ? storyblokDataAttributes(data) : {})}
		/>
	);
};
