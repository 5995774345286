"use client";

import { graphql } from "@commerce-frontend/types";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";
import { Video as VideoComponent } from "~/components/Video/Video";

export const VideoFragment = graphql(/* GraphQL */ `
	fragment VideoFragment on Video {
		__typename
		id
		video
	}
`);

type VideoBlockProps = {
	data: ResultOf<typeof VideoFragment>;
} & ComponentPropsWithoutRef<"div">;

export const Video = forwardRef<ElementRef<"div">, VideoBlockProps>(({ data, ...props }, ref) => {
	if (!data?.video) {
		return null;
	}

	return <VideoComponent ref={ref} {...props} video={data.video} />;
});
Video.displayName = "StoryblokVideo";
