"use client";

import type { Locale } from "@commerce-frontend/i18n/types";
import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import type { ImagePosition, ImageStyle } from "@commerce-frontend/types";
import { graphql } from "@commerce-frontend/types";
import { Container } from "@commerce-frontend/ui/base/Container/Container";
import { typography } from "@commerce-frontend/ui/base/Typography/typography";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { BREAKPOINTS } from "@commerce-frontend/ui/tailwind.config";
import type { ResultOf } from "@graphql-typed-document-node/core";
import { cva } from "class-variance-authority";
import { useLocale } from "next-intl";
import type { HTMLAttributes, PropsWithChildren } from "react";
import { AssetImage } from "~/components/AssetImage/AssetImage";
import { Button } from "~/components/cms/Button/Button";
import { RichText } from "~/components/cms/RichText/RichText";

const SectionHeaderFragment = graphql(/* GraphQL */ `
	fragment SectionHeaderFragment on SectionHeader {
		__typename
		id
		title
		imagePosition
		imageStyle
		bodyText {
			...RichTextFragment
		}
		buttons {
			...ButtonFragment
		}
		headerImage: image {
			...ImageAsset
		}
	}
`);

type Props = {
	data: ResultOf<typeof SectionHeaderFragment>;
} & HTMLAttributes<HTMLDivElement>;

const getTextColor = (
	hasImage: boolean,
	imagePosition?: ImagePosition | null,
	imageStyle?: ImageStyle | null,
) =>
	cn([
		"text-gray-600",
		hasImage && imagePosition === "full" && imageStyle === "dark" && "text-white",
	]);

const imageRenditions: Record<
	ImagePosition,
	{
		aspectRatio: number;
		css?: string;
	}[]
> = {
	right: [
		{
			aspectRatio: 1440 / 720,
			css: "max-lg:block hidden",
		},
		{
			aspectRatio: 608 / 640,
			css: "lg:max-xl:block hidden",
		},
		{
			aspectRatio: 1,
			css: "max-xl:hidden block",
		},
	],
	full: [
		{
			aspectRatio: 1440 / 720,
			css: "max-lg:hidden block",
		},
		{
			aspectRatio: 1024 / 720,
			css: "md:max-lg:block hidden",
		},
		{
			aspectRatio: 768 / 720,
			css: "sm:max-md:block hidden",
		},
		{
			aspectRatio: 640 / 720,
			css: "max-sm:block hidden",
		},
	],
};

const ContainerHelper = ({ shouldWrap, children }: PropsWithChildren<{ shouldWrap: boolean }>) =>
	shouldWrap ? <Container>{children}</Container> : children;

export const SectionHeader = ({ className, data }: Props) => {
	const { title, bodyText, buttons, headerImage: image, imagePosition, imageStyle } = data;
	const hasImage = Boolean(image?.filename);
	const locale = useLocale();

	return (
		<div
			className={cn(
				headerVariants({
					className,
					hasImage,
					imageStyle,
					imagePosition,
				}),
			)}
			{...storyblokDataAttributes(data)}
		>
			{imagePosition &&
				imageRenditions[imagePosition].map(({ aspectRatio, css }) => (
					<AssetImage
						key={`${aspectRatio}-${css}`}
						data={image}
						className={cn(
							imageVariants({
								imagePosition,
							}),
							css,
						)}
						aspectRatio={aspectRatio}
						sizes={
							imagePosition === "full"
								? "(max-width: 1440px) 100vw, 1440px"
								: `(max-width: ${BREAKPOINTS.lg}) 100vw, (max-width: ${BREAKPOINTS.xl}) 720px, 720px`
						}
					/>
				))}

			<ContainerHelper shouldWrap={imagePosition === "full"}>
				<div
					className={cn(
						`section-header section-header-${imageStyle ?? "light"}`,
						contentVariants({
							hasImage,
							imagePosition,
						}),
					)}
				>
					<span className={cn(typography("h1"), getTextColor(hasImage, imagePosition, imageStyle))}>
						{title}
					</span>
					{bodyText && (
						<RichText
							locale={locale as Locale}
							data={bodyText}
							contentClassName={getTextColor(hasImage, imagePosition, imageStyle)}
						/>
					)}

					{buttons && (
						<div className="mt-4 flex flex-row flex-wrap gap-3 md:mt-6">
							{buttons.map((button) => (
								<div key={button.id}>
									<Button data={button} />
								</div>
							))}
						</div>
					)}
				</div>
			</ContainerHelper>
		</div>
	);
};

export const headerVariants = cva("relative flex max-w-full flex-col bg-white lg:flex-row", {
	variants: {
		hasImage: {
			true: "lg:min-h-[582px]",
			false: "min-h-auto",
		},
		imageStyle: {
			dark: "",
			light: "text-gray-600",
		},
		imagePosition: {
			full: "lg:mx-auto lg:max-w-[1440px]",
			right: "",
		},
	},
});

export const imageVariants = cva("top-0 object-cover lg:absolute", {
	variants: {
		imagePosition: {
			right:
				"left-1/2 aspect-[375/210] h-auto w-full lg:aspect-auto lg:h-full lg:w-auto lg:max-w-[calc(50vw-32px)] min-[1504px]:max-w-[720px]",
			full: "absolute size-full",
		},
	},
});

export const contentVariants = cva("relative flex flex-col gap-4 lg:gap-5 lg:py-44 lg:pr-8", {
	variants: {
		hasImage: {
			true: "pb-16 pt-8",
			false: "py-16",
		},
		imagePosition: {
			right: "",
			full: "",
		},
	},
	compoundVariants: [
		{
			hasImage: true,
			imagePosition: "right",
			className: "w-full lg:w-1/2",
		},
		{
			hasImage: true,
			imagePosition: "full",
			className: "mt-auto",
		},
	],
});
