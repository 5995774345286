import { storyblokDataAttributes } from "@commerce-frontend/storyblok-preview/component";
import type { ButtonFragmentFragment } from "@commerce-frontend/types";
import { graphql } from "@commerce-frontend/types";
import { Button as ButtonElement } from "@commerce-frontend/ui/base/Button";
import type { ButtonProps } from "@commerce-frontend/ui/base/Button/Button";
import { forwardRef } from "react";

export const ButtonFragment = graphql(/* GraphQL */ `
	fragment ButtonFragment on Button {
		__typename
		id
		title
		variant
		size
		link {
			url
			newTab
			hash
		}
	}
`);

type Props = {
	data: ButtonFragmentFragment;
} & ButtonProps;

export const Button = forwardRef<HTMLButtonElement, Props>(
	({ data, className, ...attributes }, ref) => (
		<ButtonElement
			ref={ref}
			{...storyblokDataAttributes(data)}
			{...attributes}
			className={className}
			href={data.link.url}
			hash={data.link.hash ?? undefined}
			target={data.link.newTab ? "_blank" : undefined}
			variant={data.variant}
			size={data.size}
		>
			{data.title}
		</ButtonElement>
	),
);

Button.displayName = "Button";
