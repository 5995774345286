"use client";

import { Button } from "@commerce-frontend/ui/base/Button";
import { cn } from "@commerce-frontend/ui/helpers/styles";
import { pruneObject } from "@labdigital/toolkit";
import Player from "@vimeo/player";
import { useTranslations } from "next-intl";
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
	useEffect,
	useRef,
} from "react";
import { useCookieConsent, useCookiebot } from "~/hooks/cookiebot";

type VideoBlockProps = {
	video: string;
} & ComponentPropsWithoutRef<"div">;

export const Video = forwardRef<ElementRef<"div">, VideoBlockProps>(
	({ className, video, ...props }, ref) => {
		const t = useTranslations("Video");
		const videoFrame = useRef<HTMLDivElement>(null);
		const cookiebot = useCookiebot();
		const consentToTracking = useCookieConsent("statistics");

		useEffect(() => {
			if (!videoFrame.current || !consentToTracking) {
				return;
			}

			const player = new Player(
				videoFrame.current,
				pruneObject({
					id: video.startsWith("http") ? undefined : Number(video),
					url: video.startsWith("http") ? video : undefined,
					responsive: true,
				}),
			);

			return () => {
				player.destroy().catch(console.error);
			};
		}, [video, consentToTracking]);

		return (
			<div
				ref={ref}
				{...props}
				className={cn(
					"flex flex-row justify-center w-full rounded-md overflow-hidden from-brand-800 to-brand-600 bg-gradient-to-tr",
					consentToTracking && "aspect-16/9",
					consentToTracking || "md:aspect-16/9 lg:overflow-y-auto",
					className,
				)}
			>
				{consentToTracking ? (
					<div ref={videoFrame} className="size-full max-h-fit" />
				) : (
					<div className="size-full flex flex-col gap-xl p-3xl max-w-[720px] h-fit my-auto">
						<p className="text-white text-md font-bold">{t("cookie-consent.title")}</p>
						<p className="text-white text-sm font-medium">{t("cookie-consent.body")}</p>
						<p className="text-white text-md font-bold">{t("cookie-consent.question")}</p>
						<Button variant="secondary" className="w-fit" onClick={() => cookiebot?.show()}>
							{t("cookie-consent.button-label")}
						</Button>
					</div>
				)}
			</div>
		);
	},
);
Video.displayName = "Video";
