import { useSyncExternalStore } from "react";

const listeners: (() => void)[] = [];

// wrap the cookiebot object to trigger a state refresh even if the cookiebot object is the same instance
let cache: { cookiebot: typeof Cookiebot | null } = {
	cookiebot: null,
};

if (typeof window !== "undefined") {
	window.addEventListener("CookiebotOnConsentReady", () => {
		// update the useCookiebot hook when consent changes
		cache = { cookiebot: window.Cookiebot ? window.Cookiebot : null };
		listeners.forEach((listener) => listener());
	});
}

export type CookieBotType = typeof Cookiebot;

export const useCookiebot = (): CookieBotType | null => {
	const { cookiebot } = useSyncExternalStore(
		(listener) => {
			listeners.push(listener);

			return () => {
				const index = listeners.indexOf(listener);
				if (index >= 0) {
					listeners.splice(index, 1);
				}
			};
		},
		() => cache,
		() => cache,
	);

	return cookiebot;
};

export const useCookieConsentStatus = (): "consented" | "declined" | "no-response" => {
	const cookiebot = useCookiebot();
	if (cookiebot) {
		if (cookiebot.consented) {
			return "consented";
		}
		if (cookiebot.declined) {
			return "declined";
		}
	}

	return "no-response";
};

export const useCookieConsent = (name: keyof Omit<typeof Cookiebot.consent, "stamp">): boolean => {
	const cookiebot = useCookiebot();
	return cookiebot?.consent ? cookiebot.consent[name] : false;
};
